import { _ as b, a as z } from "./index-28E3tTLh.js";
import * as B from "react";
import c, { forwardRef as y, createElement as h, useCallback as de, useState as fe, useRef as E, useEffect as be } from "react";
import { $ as U, a as pe } from "./index-Cp-Nu9_j.js";
import { $ as $e } from "./index-egs6Xnuq.js";
import { $ as M, b as q } from "./index-CTDw4Pi4.js";
import { $ as w } from "./index-CZBInFmv.js";
import { $ as ue } from "./index-Cs5eRmmP.js";
import { $ as W } from "./index-rKUeoTl7.js";
import { $ as me } from "./index-U5S6G_il.js";
import { j as _ } from "./jsx-runtime-B6kdoens.js";
import { c as Y } from "./utils-CJ9afRe1.js";
import { I as xe } from "./Icon-AQuIivEZ.js";
const J = "Collapsible", [ve, Q] = U(J), [ge, j] = ve(J), Ce = /* @__PURE__ */ y((e, n) => {
  const { __scopeCollapsible: o, open: t, defaultOpen: a, disabled: r, onOpenChange: s, ...i } = e, [l = !1, d] = M({
    prop: t,
    defaultProp: a,
    onChange: s
  });
  return /* @__PURE__ */ h(ge, {
    scope: o,
    disabled: r,
    contentId: W(),
    open: l,
    onOpenToggle: de(
      () => d(
        ($) => !$
      ),
      [
        d
      ]
    )
  }, /* @__PURE__ */ h(w.div, b({
    "data-state": L(l),
    "data-disabled": r ? "" : void 0
  }, i, {
    ref: n
  })));
}), Ae = "CollapsibleTrigger", _e = /* @__PURE__ */ y((e, n) => {
  const { __scopeCollapsible: o, ...t } = e, a = j(Ae, o);
  return /* @__PURE__ */ h(w.button, b({
    type: "button",
    "aria-controls": a.contentId,
    "aria-expanded": a.open || !1,
    "data-state": L(a.open),
    "data-disabled": a.disabled ? "" : void 0,
    disabled: a.disabled
  }, t, {
    ref: n,
    onClick: q(e.onClick, a.onOpenToggle)
  }));
}), X = "CollapsibleContent", he = /* @__PURE__ */ y((e, n) => {
  const { forceMount: o, ...t } = e, a = j(X, e.__scopeCollapsible);
  return /* @__PURE__ */ h(
    ue,
    {
      present: o || a.open
    },
    ({ present: r }) => /* @__PURE__ */ h(Ie, b({}, t, {
      ref: n,
      present: r
    }))
  );
}), Ie = /* @__PURE__ */ y((e, n) => {
  const { __scopeCollapsible: o, present: t, children: a, ...r } = e, s = j(X, o), [i, l] = fe(t), d = E(null), $ = z(n, d), u = E(0), g = u.current, v = E(0), C = v.current, I = s.open || i, A = E(I), m = E();
  return be(() => {
    const f = requestAnimationFrame(
      () => A.current = !1
    );
    return () => cancelAnimationFrame(f);
  }, []), pe(() => {
    const f = d.current;
    if (f) {
      m.current = m.current || {
        transitionDuration: f.style.transitionDuration,
        animationName: f.style.animationName
      }, f.style.transitionDuration = "0s", f.style.animationName = "none";
      const p = f.getBoundingClientRect();
      u.current = p.height, v.current = p.width, A.current || (f.style.transitionDuration = m.current.transitionDuration, f.style.animationName = m.current.animationName), l(t);
    }
  }, [
    s.open,
    t
  ]), /* @__PURE__ */ h(w.div, b({
    "data-state": L(s.open),
    "data-disabled": s.disabled ? "" : void 0,
    id: s.contentId,
    hidden: !I
  }, r, {
    ref: $,
    style: {
      "--radix-collapsible-content-height": g ? `${g}px` : void 0,
      "--radix-collapsible-content-width": C ? `${C}px` : void 0,
      ...e.style
    }
  }), I && a);
});
function L(e) {
  return e ? "open" : "closed";
}
const Ee = Ce, we = _e, ye = he, x = "Accordion", Re = [
  "Home",
  "End",
  "ArrowDown",
  "ArrowUp",
  "ArrowLeft",
  "ArrowRight"
], [G, Ne, Pe] = $e(x), [R, co] = U(x, [
  Pe,
  Q
]), H = Q(), Z = /* @__PURE__ */ c.forwardRef((e, n) => {
  const { type: o, ...t } = e, a = t, r = t;
  return /* @__PURE__ */ c.createElement(G.Provider, {
    scope: e.__scopeAccordion
  }, o === "multiple" ? /* @__PURE__ */ c.createElement(Se, b({}, r, {
    ref: n
  })) : /* @__PURE__ */ c.createElement(De, b({}, a, {
    ref: n
  })));
});
Z.propTypes = {
  type(e) {
    const n = e.value || e.defaultValue;
    return e.type && ![
      "single",
      "multiple"
    ].includes(e.type) ? new Error("Invalid prop `type` supplied to `Accordion`. Expected one of `single | multiple`.") : e.type === "multiple" && typeof n == "string" ? new Error("Invalid prop `type` supplied to `Accordion`. Expected `single` when `defaultValue` or `value` is type `string`.") : e.type === "single" && Array.isArray(n) ? new Error("Invalid prop `type` supplied to `Accordion`. Expected `multiple` when `defaultValue` or `value` is type `string[]`.") : null;
  }
};
const [ee, Oe] = R(x), [oe, ke] = R(x, {
  collapsible: !1
}), De = /* @__PURE__ */ c.forwardRef((e, n) => {
  const { value: o, defaultValue: t, onValueChange: a = () => {
  }, collapsible: r = !1, ...s } = e, [i, l] = M({
    prop: o,
    defaultProp: t,
    onChange: a
  });
  return /* @__PURE__ */ c.createElement(ee, {
    scope: e.__scopeAccordion,
    value: i ? [
      i
    ] : [],
    onItemOpen: l,
    onItemClose: c.useCallback(
      () => r && l(""),
      [
        r,
        l
      ]
    )
  }, /* @__PURE__ */ c.createElement(oe, {
    scope: e.__scopeAccordion,
    collapsible: r
  }, /* @__PURE__ */ c.createElement(te, b({}, s, {
    ref: n
  }))));
}), Se = /* @__PURE__ */ c.forwardRef((e, n) => {
  const { value: o, defaultValue: t, onValueChange: a = () => {
  }, ...r } = e, [s = [], i] = M({
    prop: o,
    defaultProp: t,
    onChange: a
  }), l = c.useCallback(
    ($) => i(
      (u = []) => [
        ...u,
        $
      ]
    ),
    [
      i
    ]
  ), d = c.useCallback(
    ($) => i(
      (u = []) => u.filter(
        (g) => g !== $
      )
    ),
    [
      i
    ]
  );
  return /* @__PURE__ */ c.createElement(ee, {
    scope: e.__scopeAccordion,
    value: s,
    onItemOpen: l,
    onItemClose: d
  }, /* @__PURE__ */ c.createElement(oe, {
    scope: e.__scopeAccordion,
    collapsible: !0
  }, /* @__PURE__ */ c.createElement(te, b({}, r, {
    ref: n
  }))));
}), [Te, N] = R(x), te = /* @__PURE__ */ c.forwardRef((e, n) => {
  const { __scopeAccordion: o, disabled: t, dir: a, orientation: r = "vertical", ...s } = e, i = c.useRef(null), l = z(i, n), d = Ne(o), u = me(a) === "ltr", g = q(e.onKeyDown, (v) => {
    var C;
    if (!Re.includes(v.key)) return;
    const I = v.target, A = d().filter((S) => {
      var T;
      return !((T = S.ref.current) !== null && T !== void 0 && T.disabled);
    }), m = A.findIndex(
      (S) => S.ref.current === I
    ), f = A.length;
    if (m === -1) return;
    v.preventDefault();
    let p = m;
    const P = 0, O = f - 1, k = () => {
      p = m + 1, p > O && (p = P);
    }, D = () => {
      p = m - 1, p < P && (p = O);
    };
    switch (v.key) {
      case "Home":
        p = P;
        break;
      case "End":
        p = O;
        break;
      case "ArrowRight":
        r === "horizontal" && (u ? k() : D());
        break;
      case "ArrowDown":
        r === "vertical" && k();
        break;
      case "ArrowLeft":
        r === "horizontal" && (u ? D() : k());
        break;
      case "ArrowUp":
        r === "vertical" && D();
        break;
    }
    const le = p % f;
    (C = A[le].ref.current) === null || C === void 0 || C.focus();
  });
  return /* @__PURE__ */ c.createElement(Te, {
    scope: o,
    disabled: t,
    direction: a,
    orientation: r
  }, /* @__PURE__ */ c.createElement(G.Slot, {
    scope: o
  }, /* @__PURE__ */ c.createElement(w.div, b({}, s, {
    "data-orientation": r,
    ref: l,
    onKeyDown: t ? void 0 : g
  }))));
}), V = "AccordionItem", [Ve, K] = R(V), Me = /* @__PURE__ */ c.forwardRef((e, n) => {
  const { __scopeAccordion: o, value: t, ...a } = e, r = N(V, o), s = Oe(V, o), i = H(o), l = W(), d = t && s.value.includes(t) || !1, $ = r.disabled || e.disabled;
  return /* @__PURE__ */ c.createElement(Ve, {
    scope: o,
    open: d,
    disabled: $,
    triggerId: l
  }, /* @__PURE__ */ c.createElement(Ee, b({
    "data-orientation": r.orientation,
    "data-state": ne(d)
  }, i, a, {
    ref: n,
    disabled: $,
    open: d,
    onOpenChange: (u) => {
      u ? s.onItemOpen(t) : s.onItemClose(t);
    }
  })));
}), je = "AccordionHeader", Le = /* @__PURE__ */ c.forwardRef((e, n) => {
  const { __scopeAccordion: o, ...t } = e, a = N(x, o), r = K(je, o);
  return /* @__PURE__ */ c.createElement(w.h3, b({
    "data-orientation": a.orientation,
    "data-state": ne(r.open),
    "data-disabled": r.disabled ? "" : void 0
  }, t, {
    ref: n
  }));
}), F = "AccordionTrigger", Ge = /* @__PURE__ */ c.forwardRef((e, n) => {
  const { __scopeAccordion: o, ...t } = e, a = N(x, o), r = K(F, o), s = ke(F, o), i = H(o);
  return /* @__PURE__ */ c.createElement(G.ItemSlot, {
    scope: o
  }, /* @__PURE__ */ c.createElement(we, b({
    "aria-disabled": r.open && !s.collapsible || void 0,
    "data-orientation": a.orientation,
    id: r.triggerId
  }, i, t, {
    ref: n
  })));
}), He = "AccordionContent", Ke = /* @__PURE__ */ c.forwardRef((e, n) => {
  const { __scopeAccordion: o, ...t } = e, a = N(x, o), r = K(He, o), s = H(o);
  return /* @__PURE__ */ c.createElement(ye, b({
    role: "region",
    "aria-labelledby": r.triggerId,
    "data-orientation": a.orientation
  }, s, t, {
    ref: n,
    style: {
      "--radix-accordion-content-height": "var(--radix-collapsible-content-height)",
      "--radix-accordion-content-width": "var(--radix-collapsible-content-width)",
      ...e.style
    }
  }));
});
function ne(e) {
  return e ? "open" : "closed";
}
const Fe = Z, ze = Me, Be = Le, re = Ge, ce = Ke, ae = B.forwardRef(({ className: e, children: n, ...o }, t) => /* @__PURE__ */ _.jsx(
  ce,
  {
    ref: t,
    className: Y(
      "overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      e
    ),
    ...o,
    children: n
  }
));
ae.displayName = ce.displayName;
const se = c.forwardRef(({ className: e, ...n }, o) => /* @__PURE__ */ _.jsx(
  ze,
  {
    ref: o,
    className: e,
    ...n
  }
));
se.displayName = "AccordionItem";
const ie = B.forwardRef(({ className: e, children: n, ...o }, t) => /* @__PURE__ */ _.jsx(
  Be,
  {
    className: "flex flex-1",
    children: /* @__PURE__ */ _.jsx(
      re,
      {
        ref: t,
        className: Y(
          "flex flex-1 items-center justify-between py-3 font-medium transition-all [&[data-disabled]>svg]:opacity-0 [&[data-state=open]>svg]:rotate-180",
          e
        ),
        ...o,
        children: /* @__PURE__ */ _.jsxs("div", { className: "flex flex-1 items-start justify-between", children: [
          n,
          /* @__PURE__ */ _.jsx(
            xe,
            {
              name: "NavArrowDown",
              strokeWidth: 1.5,
              className: "h-5 w-5 shrink-0 transition-transform duration-200"
            }
          )
        ] })
      }
    )
  }
));
ie.displayName = re.displayName;
const Ue = Fe, ao = Object.assign(Ue, {
  Content: ae,
  Item: se,
  Trigger: ie
});
export {
  ao as A
};
